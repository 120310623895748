<template>
  <div class="md-layout-item md-size-100">
    <ShopItemsTable />
  </div>
</template>

<script>
export default {
  components: {
    ShopItemsTable: () => import("@/components/Tables/ShopItemsTable"),
  },
};
</script>
